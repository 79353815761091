import React, { useState, useEffect } from 'react';
import './OrdersList.css'; // Import your CSS file

import { useNavigate } from 'react-router-dom';

import useAxios from './useAxios';

const OrdersList = () => {  
    const navigate = useNavigate();
    const [axios] = useAxios(true);

    const [orders, setOrders] = useState([]);

    const formatToGBP = (value) => {
      return new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
      }).format(value);
    };

    const refresh = () => {
      axios.get('orders/bar', (x => setOrders(x.sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime)))));
    }

    const markOrderAsComplete = (order) => {
      axios.post('order/' + order.id + '/complete', {}, refresh);
    }

    useEffect(() => {
      refresh();
    }, []);

    useEffect(() => {
      const orderIntervalId = setInterval(() => {
        refresh();
      }, 1000);
    
        // Clean up the interval when the component is unmounted
        return () => { clearInterval(orderIntervalId); };
    }, [orders]);

  return (
    <div>
      <h2>Order</h2>
      <table className="orders-table">
        <thead>
            <tr>
                <th>Time</th>
                <th>Tab</th>
                <th>Table</th>
                <th>Items</th>
                <th>Price</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            {orders.map((order) => { return <tr key={order.id} className={order.complete === false ? "order-incomplete" : ""}>
                    <td>{order.dateTime}</td>
                    <td>{order.tab.name}</td>
                    <td>{order.table.name}</td>
                    <td>{order.items.map(x => <p>{x.quantity} x {x.item.name}</p>)}</td>
                    <td>{formatToGBP(order.totalCost)}</td>
                    <td>
                        { order.complete === false && <button className="edit-button" onClick={() => markOrderAsComplete(order)}>Mark as Complete</button> }
                    </td>
                </tr>
              }
            )}
        </tbody>
      </table>
    </div>
  );
};

export default OrdersList;
