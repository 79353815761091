import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import './Login.css';

import useAxios from './useAxios';

import PinPad from './PinPad'

const Login = ({ onLogin }) => {
  const { tabId } = useParams();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const [error, setError] = useState(false);

  const [axios] = useAxios();

  const handleError = (error) =>
  {
   setError(error);
   setButtonDisabled(false); 
  }

  const handleLogin = async () => {
    setError(null);
    setButtonDisabled(true);
    setTimeout(() => {
      axios.post('auth/login', { username, password }, onLogin, handleError);
    }, 1000);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };
  
  return (
    <div className="login-container">
      <div className="login-form">
          <h2>Login</h2>
          <label>
            Username: 
          <br/>
            <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
          </label>
          <br/>
          <label>
            Password:
          <br/>
            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} onKeyUp={handleKeyPress} />
          </label>
          <br/>
          {error ? <label>Failed Login</label> : null}
          <button onClick={handleLogin} disabled={isButtonDisabled}>Login</button>
      </div>
    </div>
  );
};

export default Login;
