// src/Home.js

import React, { useEffect, useState, useRef } from 'react';
import './App.css';
import { useNavigate } from 'react-router-dom';
import Confirm from './Confirm';

import useAxios from './useAxios';

const OrderDetails = ({ tab, logout, onSave }) => {
  const navigate = useNavigate();
  const [axios] = useAxios(true, logout);
  const [products, setProducts] = useState([]);
  const [items, setItems] = useState([]);
  const [orderSummary, setOrderSummary] = useState(null);
  const confirmOrder = useRef();

  const [table, setTable] = useState(null);

  const formatToGBP = (value) => {
    return new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP',
    }).format(value);
  };

  useEffect(() => {
    axios.get('products', setProducts);
  }, []);

  const getItemsSummary = () => {
    var total = items.reduce((acc, item) => acc + (item.cost * item.quantity), 0);

    return <div>
      {items.map(x => <p>{x.description} x{x.quantity}</p>)}
      <p>Total: {formatToGBP(total)}</p>
    </div>
  }

  const handleSaveOrder = (result) => {
    if (result === "No")
    {
      return;
    }

    var order = {
      tab: { id: tab.id, name: tab.name },
      table: null,
      type: "bar",
      dateTime: new Date(),
      items: items.map(x => { return {
        item: x.item,
        cost: x.cost,
        quantity: x.quantity
      }})
    }

    axios.post('order', order, onSave);
  }

const showConfirmDialog = () => {
  setOrderSummary(getItemsSummary());
  confirmOrder.current.open();
}

  const removeItem = (p) => {
    var item = items.find(item => item.id === p.id);

    if (item)
    {
      var updatedItems = items.map(item =>
        item.id === p.id ? { ...item, quantity: item.quantity > 0 ? item.quantity - 1 : 0 } : item
      );

      setItems(updatedItems);
    }

    setOrderSummary(getItemsSummary());
  }

  const addItem = (p) => {
    var item = items.find(item => item.id === p.id);

    if (item)
    {
      var updatedItems = items.map(item =>
        item.id === p.id ? { ...item, quantity: item.quantity + 1 } : item
      );

      setItems(updatedItems);
    }
    else
    {
      setItems(prevItems => [...prevItems, { id: p.id, item: p, cost: p.cost, description: p.description, quantity: 1}]);
    }
  }

  if (products.length === 0)
  {
    return <div>Loading...</div>
  }

  return (
    <div style={{display:"flex", flexDirection: "column", alignItems: "center", maxHeight: "80vh", width: "100%", overflow:"auto"}}>
      <h2>New order</h2>
      {
        products.map(group => 
          <div style={{width: "95%"}}>
            <h2>{group[0].category.name}</h2>
            <div style={{display:"flex", flexDirection: "row", alignItems: "center", overflowY:"hidden", overflowX: "auto"}}>
              {
                group.map(product => 
                  <div style={{display:"flex", flexDirection: "column", padding: "30px", alignItems: "center"}}>
                    <img src={product.image} style={{maxHeight: "20vh"}}/>
                    <h4>{formatToGBP(product.cost)}</h4>
                    <div style={{display:"flex", flexDirection: "row", alignItems: "center"}}>
                      <button onClick={() => removeItem(product)}>-</button>
                      <label style={{padding: "10px"}}>{items.find(x => x.id === product.id)?.quantity ?? 0}</label>
                      <button onClick={() => addItem(product)}>+</button>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        )
      }
      <button onClick={showConfirmDialog}>Send Order</button>
      <Confirm ref={confirmOrder} title="Send Order?" message={orderSummary} buttons={["Yes", "No"]} onAnswer={handleSaveOrder} canClose={false} />
    </div>
  );
};

export default OrderDetails;
