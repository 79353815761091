// src/Home.js

import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import './App.css';

import Modal from './Modal';

const Confirm = forwardRef(({ title, message, buttons, onAnswer, canClose }, ref) => {
  const modal = useRef();

  // Expose the function to the parent component through the ref
  useImperativeHandle(ref, () => ({
    open: () => modal.current.open()
  }));

  return (
      <Modal size="small" ref={modal} canClose={canClose}>
        <h2 style={{paddingTop: 0}}>{title}</h2>
        {
          message
        }
        <div style={{display: "flex", flexDirection: "row", gap: "20px"}}>
          {
            buttons.map(x => <button onClick={() => { modal.current.close(); onAnswer(x); }}>{x}</button>)
          }
        </div>
      </Modal>
  );
});

export default Confirm;
