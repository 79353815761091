// src/Home.js

import React, { useEffect, useState, useRef } from 'react';
import './App.css';
import './Tabs.css';

import useAxios from './useAxios';

import TabDetails from './TabDetails'

import Modal from './Modal';

const Tab = ({ tab, onclick }) => (
  <a onClick={onclick}>
    <div className="tile">
      <h3>{tab.name}</h3>
    </div>
  </a>
);

const Tabs = ({ logout }) => {
  const [axios] = useAxios(true, logout);
  const modal = useRef();

  const [tabs, setTabs] = useState([]);
  const [tab, setTab] = useState(null);

  useEffect(() => {
    axios.get('tabs/open', setTabs);
  }, []);

  return (
    <div className="tabs-container">
      <h2>Tabs</h2>
      <div className="tabs-container-group">
        { tabs.map((tab) => {
          return <Tab key={tab.id} tab={tab} onclick={() => { setTab(tab); modal.current && modal.current.open() }} />
        })}
      </div>
      
      <Modal size="fullscreen" ref={modal} onClose={() => window.location.reload()}>
        {tab !== null && (
          <TabDetails parent={modal} showQRCode={true} showCloseTab={true} tabId={tab.id} logout={logout} />
        )}
      </Modal>
    </div>
  );
};

export default Tabs;
