// src/App.js

import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import useSessionStorage from './useSessionStorage';
import useAxios from './useAxios';

import NavBar from './NavBar';
import MatchTables from './MatchTables';
import Login from './Login';
import Scoreboard from './Scoreboard';
import Competition from './Competitions/Competition';
import CompetitionDraw from './Competitions/CompetitionDraw';
import CompetitionsList from './Competitions/CompetitionsList';
import ScoreboardOverlay from './ScoreboardOverlay';
import TableTimers from './TableTimers';
import Tabs from './Tabs';
import PinPad from './PinPad';

import DartsScoreboard from './Scoreboards/Darts/DartsScoreboard';

import PlayersList from './PlayersList';
import Player from './Player';
import TablesList from './TablesList';
import Table from './Table';
import MatchesList from './MatchesList';
import Match from './Match';
import ProductsList from './ProductsList';
import Product from './Product';
import TabDetails from './TabDetails';
import TabletLogin from './TabletLogin'
import ConfigurationDashboard from './ConfigurationDashboard'
import OrdersList from './OrdersList';


const App = () => {
  const [token, setToken, clearToken] = useSessionStorage('token', null);
  const [tenant, setTenant, clearTenant] = useSessionStorage('tenant', null);
  const navigate = useNavigate();
  const [logoutPath, setLogoutPath] = useState('/');

  const [menus, setMenus] = useSessionStorage("menus", []);

  const handleLogin = (data) => {
    setMenus([
      { label: "Home", url: '/'},
      { label: "Competitions", url: "/competitions" },
      { label: "Matches", url: "/matchtables" },
      { label: "Configuration", url: "/configuration/" },
    ]);
    
    console.log(data.tenant);
    setTenant(data.tenant);
    setToken(data.token);
    navigate('/');
  };

  const handleTabletLogin = (data) => {
    setLogoutPath('/login/tablet/' + data.id);
    setToken(data.token);
    navigate('/tablet/tab/' + data.id);
  };

  const logout = (data) => {
    setToken(null);
    navigate(logoutPath);
  };

  const RequiresAuth = ({ children }) => {  
    if (!token) {
      return <Navigate to="/login" />;
    }
  
    return (<div>
      {
        React.Children.map(children, child => {
          // Clone the child with additional props
          return React.cloneElement(child, { logout });
        })
      }
    </div>);
  }

  return (
    <div>
      <NavBar visible={token !== null} menus={menus} logout={logout}/>
      <Routes>
        <Route path="/login" element={<Login onLogin={handleLogin} />} />
        <Route path="/login/tablet/:tabId" element={<TabletLogin onLogin={handleTabletLogin} />} />
        <Route path="/pinpad" element={<PinPad />} />
        <Route path="/" element={<RequiresAuth><MatchTables /></RequiresAuth>} />
        <Route path="/darts/scoreboard" element={<RequiresAuth><DartsScoreboard /></RequiresAuth>} />
        <Route path="/matchtables" element={<RequiresAuth><MatchTables /></RequiresAuth>} />
        <Route path="/scoreboard/:id" element={<RequiresAuth><Scoreboard /></RequiresAuth>} />
        <Route path="/players" element={<RequiresAuth><PlayersList /></RequiresAuth>} />
        <Route path="/player/:id" element={<RequiresAuth><Player /></RequiresAuth>} />
        <Route path="/tables" element={<RequiresAuth><TablesList /></RequiresAuth>} />
        <Route path="/table/:id" element={<RequiresAuth><Table /></RequiresAuth>} />
        <Route path="/matches" element={<RequiresAuth><MatchesList /></RequiresAuth>} />
        <Route path="/match/:id" element={<RequiresAuth><Match /></RequiresAuth>} />
        <Route path="/match/:id/:tableId" element={<RequiresAuth><Match /></RequiresAuth>} />
        <Route path="/competitions" element={<RequiresAuth><CompetitionsList /></RequiresAuth>} />
        <Route path="/competition/:id" element={<RequiresAuth><Competition /></RequiresAuth>} />
        <Route path="/competition/:id/draw" element={<RequiresAuth><CompetitionDraw readonly={false}/></RequiresAuth>} />

        <Route path="/configuration/" element={<RequiresAuth><ConfigurationDashboard /></RequiresAuth>} />
        <Route path="/configuration/players" element={<RequiresAuth><PlayersList /></RequiresAuth>} />
        <Route path="/configuration/player/:id" element={<RequiresAuth><Player /></RequiresAuth>} />
        <Route path="/configuration/tables" element={<RequiresAuth><TablesList /></RequiresAuth>} />
        <Route path="/configuration/table/:id" element={<RequiresAuth><Table /></RequiresAuth>} />
        <Route path="/configuration/matches" element={<RequiresAuth><MatchesList /></RequiresAuth>} />
        <Route path="/configuration/match/:id" element={<RequiresAuth><Match /></RequiresAuth>} />
        <Route path="/configuration/match/:id/:tableId" element={<RequiresAuth><Match /></RequiresAuth>} />
        <Route path="/configuration/products" element={<RequiresAuth><ProductsList /></RequiresAuth>} />
        <Route path="/configuration/product/:id" element={<RequiresAuth><Product /></RequiresAuth>} />

        <Route path="/tabletimers" element={<RequiresAuth><TableTimers /></RequiresAuth>} />

        <Route path="/orders" element={<RequiresAuth><OrdersList /></RequiresAuth>} />

        <Route path="/tabs" element={<RequiresAuth><Tabs /></RequiresAuth>} />
        <Route path="/tab/:id" element={<RequiresAuth><TabDetails showQRCode={true} showCloseTab={true} /></RequiresAuth>} />

        <Route path="/public/overlay/:id" element={<ScoreboardOverlay />} />
        <Route path="/public/competition/:id" element={<CompetitionDraw readonly={true} />} />

        <Route path="/tablet/tab/:id" element={<RequiresAuth><TabDetails showQRCode={false} showCloseTab={false} /></RequiresAuth>} />
      </Routes>
    </div>
  );
};

export default App;
