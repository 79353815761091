// src/Home.js

import React, { useEffect, useState, useRef } from 'react';
import './App.css';
import './TableTimers.css';

import useAxios from './useAxios';

import Confirm from './Confirm';
import moment from 'moment';

const Timer = ({ tablename, duration, onclick }) => (
  <a onClick={onclick}>
    <div className="tile">
      { duration ?
      <div className="busy">
        <div>{tablename}</div>
        <div>{moment.utc(duration.asMilliseconds()).format('HH:mm:ss')}</div>
      </div> :
      <div>
        <div>{tablename}</div>
        <div></div>
      </div>
      }
    </div>
  </a>
);

const TableTimers = ({ logout }) => {
  const [axios] = useAxios(true, logout);
  const totalTimeModal = useRef();
  const offerModal = useRef();

  const [lastTotalTime, setLastTotalTime] = useState("");

  const [timers, setTimers] = useState(null);
  const [selectedTimer, setSelectedTimer] = useState(null);

  const refresh = () => {
    axios.get('tabletimers', setTimers);
  }

  useEffect(() => {
    refresh();
  }, []);

  
  useEffect(() => {
    if (timers !== null)
    {
        const clockIntervalId = setInterval(() => {
          for (var i in timers)
          {
            if (timers[i].start !== null && timers[i].end === null)
            {
              const seconds = moment().diff(timers[i].start, 'seconds');
              const duration = moment.duration(seconds, 'seconds');
              timers[i].duration = duration;
            }
          };

          setTimers([...timers]);
        }, 500);
  
        // Clean up the interval when the component is unmounted
        return () => { clearInterval(clockIntervalId); };
    }
  }, [timers]);

  const getOfferSelection = (timer) => {
    setSelectedTimer(timer);
    offerModal.current.open();
  }

  const startTimer = (offer) => {
    selectedTimer.offer = offer;
    axios.post('tabletimer/start', selectedTimer, refresh);
  }

  const endTimer = (timer) => {
    axios.post('tabletimer/end', timer, handleTimerEnded);
  }

  const handleTimerEnded = (timer) => {
    let start = moment(timer.start);
    let end = moment(timer.end);
    let diff = end.diff(start, 'seconds');

    let duration = moment.duration(diff, 'seconds');
    let format = moment.utc(duration.asMilliseconds()).format('HH:mm:ss');
    setLastTotalTime(format);
    totalTimeModal.current.open();
  }

  if (timers === null)
  {
    return <h1>Loading...</h1>
  }

  return (
    <div className="tabletimers-container">
      <h2>Timers</h2>
      <div className="tabletimers-container-group">
        { timers.map((timer) => {
          return <Timer key={timer.table.id} tablename={timer.table.name} duration={timer.duration} onclick={() => timer.duration ? endTimer(timer) : getOfferSelection(timer)} />
        })}
      </div>
      
      <Confirm ref={totalTimeModal} onClose={refresh} title={"Total Time: " + lastTotalTime} buttons={["OK"]} onAnswer={refresh} canClose={false} />
      <Confirm ref={offerModal} onClose={refresh} title={"Offer: " + lastTotalTime} buttons={["None", "5%", "10%", "2 hours", "3 hours", "5 hours", "Singles League"]} onAnswer={startTimer} canClose={false} />
    </div>
  );
};

export default TableTimers;
